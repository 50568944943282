.hg-video-section {
  padding: 60px 0px;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(247,247,248,1) 100%);
  .hg-title-block {
  	&__title {
      font-size: 44px;
      line-height: 48px;
      text-transform: none;
  	}
  	&__subtitle {
      font-size: 18px;

  	}
    h4 {
      a {
        color: $turqoise;
      }
    }
  }
}
.hg__playVideo:visited {
  outline:none;
}
.hg__playVideo:focus {
  outline:none;
}
.hg-video-container {
  &--content {
    padding: 30px 128px 30px 0px;

  p {
    margin-bottom:35px;
  }
    @include max_desktop {
      padding-right: 0px;
    }

  }
  &__but{
    @include max_desktop {
      text-align:left;
      padding-left: 0px;
    }
  }
  &--media-container{
    display:flex;
    height: 500px;
    margin:0 auto;
    position: relative;
    @include max_mobile {
      height: 300px;
    }
  }
  &__bg {
    display: block;
    background-image: url("../../images/bar.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: scroll;
    width:100%;
    height: 100%;
  }
  &__iconbox-video {
      align-self: center;
      margin: 0 auto;
      @include max_desktop {
        width:100%;
        .hg-column-video {
          width: calc(100% - 105px);
          margin:0 auto;
          padding:0;
        }
      }
      @include max_mobile {
        width:100%;
        .hg-column-video {
          width: calc(100% - 102px);
          margin:0 auto;
          padding:0;
        }
      }
  }
}
.hg-vcontainer {
  width:100%;
  text-align:left;
}
.hg-bgsource {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  
}

.hg-video-container__iconbox-video {
  z-index: 1;
  text-align: center;
  .hg__playVideo{
      display: inline-block;
      width: 96px;
      height: 96px;
  }
}
