.page-subheader {
  position: relative;
  margin-top: 67px;
  z-index: 0;
  width: 100%;
  height:auto;
  @media screen and (min-width:760px) {
    .container {
    padding:0;
  }
  }
  .title {
    position:relative;
    float:left;
    padding: 5px 0px;
  }
  h4 {
    font-family: $btnFont;
    font-size: 24px;
    font-weight: 400;
  }
  .price {
    position:relative;
    float:right;
    line-height:45px;
    top: 7px;
    .price-value {
      font-size: 18px;
    }
    .price-unit {
      color: #999;
      font-size: 11px;
      font-weight: 600;
    }
  }
}
.psub--dark {
  background-color: $templateColor;
  .breadcrumb {
    li {
      color: $lightgrey;
      a {
        color:$lightgrey;
      }
    }
  }
}
.psub--light {
  background-color: $subheader;
  .breadcrumb {
    li {
      color: rgba(0,0,0,.5);
      a {
        color: rgba(0,0,0,.8);
      }
    }
  }
}
.breadcrumb {
  background: none;
  padding: 15px 0px;
  li+li:before {
    margin: 0 auto;
    padding: 8px;
    color: black;
    content: "/\00a0";
  }
  li {
    float: left;
    position: relative;
    text-transform: uppercase;
    font-size: 11px;
    margin: 0;
  }
}
.page-subheader-container {
  width:90%;
  padding: 10px 0px;
  margin: 0 auto;
  height: 75px;
}
