/* Homepage */
body {
    font-size: 13px;
    font-family: $defaultFont;
		line-height: 1.7;
    color: $templateColor;

    #page_wrapper {
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: scroll;
      }
}

#page_wrapper {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    z-index: 0;
}
.site-logo {
    width: 100%;
    margin: 0;
    display: table;
}
ul {
	li{
		display: inline-block;
		margin: 0 10px;
		position: relative;
	}
}
/* helpfull clases*/
.flex {
    display: flex;
    flex-wrap: wrap;
}
.flex-row {
  display: flex;
  flex-direction: row;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
}
.flex-column {
  display: flex;
  flex-direction: column;

}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}

.flex-start-x {
  justify-content: flex-start;
}
.flex-center-y {
    align-items: center;
}
.flex-col {
    flex: 1;
}
.flex-center-x {
   justify-content: center;
}
.flex-end-x {
    justify-content: flex-end;
}
.flex-col-end {
    align-self: flex-end;
}
.flex-basis-auto {
  flex-basis: auto;
}
.flex-grow-0 {
  flex-grow: 0;
}
.pgb-100 {
  padding-bottom:100px;
}
.pgt-100 {
  padding-top:100px;
}
.mgt-60 {
  margin-top: 60px;
}
.mgb-60 {
  margin-bottom: 60px;
}
.mgt-120 {
  margin-top: 120px;
}
.bg-color--white {
  background-color: #fff;
}
.bg-color--grey {
  background-color: #f5f5f5;
}
.overlay--opacity {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #000;
  opacity: .2;
}
.txt-center {
  text-align:center;
}
h1 {
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 20px;
  font-family:$defaultFont;
}
h3 {
	font-family: $titleFont;
	font-size: 24px;
	font-weight: 700;
	color: $titleColor;
	text-transform: uppercase;
}

h4 {
	font-family: $titleFont;
	font-size: 18px;
	font-weight: 400;
	color: $titleColor;
	font-style: normal;
	margin-bottom: 10px;
}

a:hover {
	color: $hoverColor;
	text-decoration: none;
}


span {
  color: $hoverColor;
}

th, td {
    text-align: center;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 35px;
}

/* checkout table*/
.hg-booking {
  margin-bottom: 30px;
  border-spacing:0;
  border-collapse:collapse;
  th {
    color: #2a2a2a;
    padding: 10px 17px;
    text-align: center;
  }
  td {
    padding: 40px 17px;

  }
  &__checkin {
    @include max_desktop {
      display:none;
    }
  }
  &__delete {
    @include max_mobile {
      padding:10px 2px!important;
    }
  }
  &__qty, &__capacity {
    @include max_mobile {
      padding:10px 0px!important;
    }
  }
  &__checkout {
    @extend .hg-booking__checkin;
  }
  &__night {
    @extend .hg-booking__checkin;
  }
}
.hg_subtotal{
  td {
    text-align: left;
  }
  &__value {
    float:right;
    color: $cataloguetitle;
    font-weight: 700;
    margin: -10px 0;
    padding: 10px 0 0 26px;
    border-left: 1px solid #eee;
    text-align: right;
  }
}
.hg_grand_total {
  td {
      text-align: left;
  }
  &__value {
      @extend .hg_subtotal__value;
  }
}
.hg-align-right {
  text-align:right;
}
.hg_tax td,.hg_adv-pay td {
  text-align: left;
}
.hg_tax__value, .hg_adv-pay__value {
  float:right;
  color: $cataloguetitle;
  margin: -10px 0;
  padding: 10px 0 0 26px;
  border-left: 1px solid #eee;
  text-align: right;
}
.hg_payment_all {
  label {
    text-align: right;
    float: right;
    position: relative;
  }
}
.delete_cart {
  color:#555;
}
button {
  height: 50px;
	outline: none;
	border: none;
	text-decoration: none;
	text-transform: uppercase;
	padding: 0px 30px;
	border-radius: 3px;
  -webkit-transition: all 0.3s;
-moz-transition: all 0.3s;
-o-transition: all 0.3s;
-ms-transition: all 0.3s;
transition: all 0.3s;
}

.btn-fullwhite {
    font-family: $titleFont;
    background:none;
    font-size: 14px;
    font-weight: 700;
    color: #0a0a0a!important;
    letter-spacing: 1px;
    text-transform: uppercase;

}
.hb_btn.box-shadow {
  -webkit-box-shadow: 0px 11px 27px 0px rgba(154, 49, 188, 0.33);
  box-shadow: 0px 11px 27px 0px rgba(154, 49, 188, 0.33);
}
.hg-gallery-but {
  width: 100%;
  margin-top: 30px;
  padding: 10px 20px;
  background: white;
  @include max_desktop {
    margin-top: 60px;
  }
}

input[type=text],input[type=email] {
	max-width: 100%;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	margin-bottom: 15px;
  border: 0px;
	padding: 26px 16px 6px;
}

//LABEL POSITION
label {
    color: $formColor;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    cursor: text;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
    //left: 30px;
    margin-top: 6px;
}
input[type=password] {
	max-width: 100%;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	margin-bottom: 15px;
  border: 0px!important;
	padding: 26px 16px 6px;
}
textarea {
	border: 0px;
  border-radius: 4px;
  padding: 26px 16px 6px;
  margin-bottom: 15px;
  width: 100%;
}

/* Search Page */
.btn_add_to_cart {
      font-family: $titleFont;
      background: $turqoise;
      padding: 10px 25px;
      color: #FFF;
      box-shadow: 0px 10px 25px 0px rgba(0,0,0,0.08);
      border-radius: 3px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 1px;
}
.btn_add_to_cart:hover {
    opacity: 0.8;
}

input[type=checkbox] {
    cursor: pointer;
    text-align: center;
    height: 14px;
    width: 14px;
    min-width: 14px;
    transition: .05s border-color ease-in-out;
    outline: 0!important;
    -webkit-appearance: none;
    border: 1px solid #333;
    margin-top: 3px;
}
input[type=checkbox]:before {
  //TODO GLIPHICONS
    //font-family: 'glyphicons_halflingsregular'!important;
    //content: '\e013';
    font-family: fontAwesome!important;
    content: '';
    display: none;
    color: $check;
}
input[type=checkbox]:checked:before {
  margin-top: -5px;
  float: left;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  font: 400 20px/1 dashicons;
  speak: none;
  -webkit-font-smoothing: antialiased;

}
